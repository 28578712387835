import * as moment from 'moment';
import { environment } from '../../environments/environment';

import { ConstantObject } from './interfaces/constant-object';

export class AppConstants {
  public static get maxInteger(): number {
    return Number.MAX_SAFE_INTEGER;
  }

  public static get baseUrl(): string {
    return environment.apiBaseUrl;
  }

  public static get reportsUrl(): string {
    return environment.apiReportsUrl;
  }

  public static get authUrl(): string {
    return environment.authBaseUrl;
  }

  public static get datatablePtBr(): Object {
    return {
      'sEmptyTable': 'Nenhum resultado encontrado',
      'sInfo': 'Exibindo _START_-_END_ de _TOTAL_ resultados',
      'sInfoEmpty': 'Nenhum resultado',
      'sInfoFiltered': '(Filtrados de _MAX_ resultados)',
      'sInfoPostFix': '',
      'sInfoThousands': '.',
      'sLengthMenu': 'Exibir _MENU_ resultados',
      'sLoadingRecords': 'Carregando...',
      'sProcessing': 'Processando...',
      'sZeroRecords': 'Nenhum resultado encontrado',
      'sSearch': 'Pesquisar',
      'oPaginate': {
        'sNext': '<span class="icon-right-open"></span>',
        'sPrevious': '<span class="icon-left-open"></span>',
        'sFirst': 'Primeiro',
        'sLast': 'Último'
      },
      'oAria': {
        'sSortAscending': ': Ordenar colunas de forma ascendente',
        'sSortDescending': ': Ordenar colunas de forma descendente'
      }
    };
  }

  public static get masterTypes(): ConstantObject[] {
    return [{
      name: 'Ticket',
      value: 'ticket'
    }, {
      name: 'Cashless',
      value: 'prepaid_cashless'
    }];
  }

  public static get deviceTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Telefone',
      value: 'phone'
    }, {
      name: 'Lio',
      value: 'lio'
    }, {
      name: 'GetNet',
      value: 'getnet'
    }, {
      name: 'PagSeguro',
      value: 'pagseguro'
    }, {
      name: 'Totem',
      value: 'totem'
    }, {
      name: 'BeerMe',
      value: 'beerme'
    }, {
      name: 'Outro',
      value: 'other'
    }];
  }

  public static get integrationTypes(): Object[] {
    return [{
      name: 'Ingresso',
      value: 'ticket'
    }, {
      name: 'Ingresso e Ponto de Venda',
      value: 'ticket_and_pos'
    }, {
      name: 'Ponto de Venda',
      value: 'pos'
    }, {
      name: 'Análise de dados',
      value: 'data_analytics'
    }];
  }

  public static get statusTypes(): object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Habilitado',
      value: 'enable'
    }, {
      name: 'Desabilitado',
      value: 'disabled'
    }];
  }

  public static get permissionsTypes(): Array<string> {
    return ['all', 'private', 'public'];
  }

  public static get notificationsTypes(): Array<string> {
    return ['all', 'read', 'unread'];
  }

  public static get eventStatus(): Object[] {
    return [{
      name: 'Todos',
      value: '',
      color: ''
    }, {
      name: 'Pendente',
      value: 'pending',
      color: 'warning-color'
    }, {
      name: 'Pronto para Operação',
      value: 'ready',
      color: 'success-color'
    }, {
      name: 'Desabilitado',
      value: 'disabled',
      color: 'danger-color'
    }, {
      name: 'Realizado',
      value: 'done',
      color: 'info-color'
    }];
  }

  public static get devideStatus(): Object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Habilitado',
      value: 'enabled'
    }, {
      name: 'Desabilitado',
      value: 'disabled'
    }];
  }

  public static get pendenceEntities(): ConstantObject[] {
    return [{
      name: 'Todas',
      value: ''
    }, {
      name: 'Produto',
      value: 'MenuItem'
    }, {
      name: 'Técnico',
      value: 'Technician'
    }, {
      name: 'Ponto de venda',
      value: 'PointOfSale'
    }, {
      name: 'Forma de pagamento',
      value: 'PaymentType'
    }];
  }

  public static get booleanTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: 'all'
    }, {
      name: 'Sim',
      value: 'true'
    }, {
      name: 'Não',
      value: 'false'
    }];
  }

  public static get paymentAcquirerTypes(): ConstantObject[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Crédito',
      value: 'credit'
    }, {
      name: 'Débito',
      value: 'debit'
    }, {
      name: 'Dinheiro',
      value: 'cash'
    }, {
      name: 'Voucher',
      value: 'voucher'
    }, {
      name: 'Rappi Pay',
      value: 'rappipay'
    }, {
      name: 'PIX',
      value: 'pix'
    }];
  }

  public static get deleteNotifyConfig(): Object {
    return {
      titleMaxLength: 150,
      timeout: 0,
      showProgressBar: false,
      closeOnClick: false,
      backdrop: 0.5,
      position: 'centerCenter'
    }
  }

  public static get datepickerConfig(): Object {
    return {
      format: 'DD/MM/YYYY',
      locale: moment.locale('pt-BR'),
      disableKeypress: true,
      returnedValueType: 'string'
    }
  }

  public static get datetimepickerConfig(): any {
    return {
      format: 'DD/MM/YYYY - HH:mm',
      locale: moment.locale('pt'),
      showTwentyFourHours: true,
      disableKeypress: true,
      returnedValueType: 'string',
      drops: 'down'
    }
  }

  public static get timepickerConfig(): Object {
    return {
      format: 'HH:mm',
      mode: 'time',
      locale: moment.locale('pt'),
      showTwentyFourHours: true,
      disableKeypress: true,
      returnedValueType: 'string',
      drops: 'down'
    }
  }

  public static get doughnutConfig(): Object {
    return {
      'responsive': true,
      'maintainAspectRatio': false,
      'legend': {
        'position': 'right',
        'labels': {
          'boxWidth': 15
        }
      },
      'tooltips': {
        'callbacks': {
          'label': function (tooltipItem: any, data: any) {
            return data.labels[tooltipItem.index];
          }
        }
      }
    };
  }

  public static get barConfig(): Object {
    return {
      'responsive': true,
      'scales': {
        'yAxes': [{
          'ticks': {
            'beginAtZero': true
          }
        }]
      },
      'tooltips': {
        'callbacks': {
          'label': function (tooltipItem: any, data: any) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
          }
        }
      }
    };
  }

  public static get featureTypes(): Object[] {
    return [{
      name: 'Configurar Cardápio',
      value: 'menu_configuration'
    }, {
      name: 'Visualizar Operações',
      value: 'operations_report'
    }, {
      name: 'Relatório Caixa',
      value: 'pos_reports'
    }, {
      name: 'Fechar Caixa',
      value: 'pos_closing'
    }, {
      name: 'Cancelar Sangria',
      value: 'withdrawal_cancellation'
    }, {
      name: 'Sangria',
      value: 'withdrawal'
    }, {
      name: 'Cancelar Estorno',
      value: 'refund_cancellation'
    }, {
      name: 'Estorno',
      value: 'refund'
    }, {
      name: 'Cancelar Venda',
      value: 'ticket_sale_cancellation'
    }, {
      name: 'Reimprimir',
      value: 'reprint'
    }, {
      name: 'Abrir Evento',
      value: 'open_event'
    }];
  }

  public static get operationFilterTypes(): Object[] {
    return [{
      name: 'Todas',
      value: ''
    }, {
      name: 'Vendas',
      value: 'ticket_sale'
    }, {
      name: 'Vendas Canceladas',
      value: 'ticket_refund'
    }, {
      name: 'Estornos',
      value: 'refund'
    }, {
      name: 'Estornos Cancelados',
      value: 'refund_canceled'
    }, {
      name: 'Vendas Re-impressas',
      value: 'reprint'
    }, {
      name: 'Sangrias',
      value: 'withdrawal'
    }, {
      name: 'Sangrias Canceladas',
      value: 'withdrawal_canceled'
    }];
  }

  public static get operationTypes(): Object[] {
    return [{
      name: 'Venda',
      value: 'ticket_sale'
    }, {
      name: 'Estorno',
      value: 'ticket_refund'
    }, {
      name: 'Sangria',
      value: 'withdrawal'
    }]
  }

  public static get cashlessOperationTypes(): Object[] {
    return [{
      name: 'Recarga',
      value: 'recharge'
    }, {
      name: 'Consumo',
      value: 'consumption'
    }, {
      name: 'Ativação',
      value: 'card_activation'
    }, {
      name: 'Devolução',
      value: 'card_return'
    }, {
      name: 'Bloqueio',
      value: 'card_block'
    }, {
      name: 'Estorno de consumo',
      value: 'consumption_refund'
    }, {
      name: 'Estorno de recarga',
      value: 'recharge_refund'
    }]
  }

  public static get clientLegalTypes(): ConstantObject[] {
    return [{
      name: 'Pessoa Física',
      value: 'physical_person'
    }, {
      name: 'Pessoa Jurídica',
      value: 'legal_person'
    }]
  }


  public static get occurrenceTypes(): Object[] {
    return [{
      name: 'Cancelamento',
      value: 'cancellation'
    }, {
      name: 'Reimpressão',
      value: 'reprint'
    }]
  }

  public static get paymentStatus(): Object[] {
    return [{
      name: 'Autorizado',
      value: 'authorized'
    }, {
      name: 'Cancelado',
      value: 'cancelled'
    }]
  }

  public static get cashierTypes(): Object[] {
    return [{
      name: 'Todos os caixas',
      value: ''
    }, {
      name: 'Somente produção',
      value: true
    }]
  }

  public static get eventTypes(): Object[] {
    return [{
      name: 'Ticket',
      value: 'ticket'
    }, {
      name: 'Cashless',
      value: 'prepaid_cashless'
    }, {
      name: 'Bilheteria',
      value: 'ticket_office'
    }];
  }

  public static get operationCashlessFilterTypes(): Object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Recargas',
      value: 'recharge'
    }, {
      name: 'Recargas Canceladas',
      value: 'recharge_canceled'
    }, {
      name: 'Estorno de Recargas',
      value: 'recharge_refund'
    }, {
      name: 'Estorno de Recargas Canceladas',
      value: 'recharge_refund_canceled'
    }, {
      name: 'Consumos',
      value: 'consumption'
    }, {
      name: 'Consumos Cancelados',
      value: 'consumption_canceled'
    }, {
      name: 'Estorno de Consumo',
      value: 'consumption_refund'
    }, {
      name: 'Estorno de Consumo Cancelado',
      value: 'consumption_refund_canceled'
    }, {
      name: 'Ativações',
      value: 'card_activation'
    }, {
      name: 'Devoluções',
      value: 'card_return'
    }, {
      name: 'Sangrias',
      value: 'withdrawal'
    }];
  }

  public static get operationCashlessPospaidFilterTypes(): Object[] {
    return [{
      name: 'Todos',
      value: ''
    }, {
      name: 'Pagamento',
      value: 'card_payment'
    }, {
      name: 'Recarga',
      value: 'recharge'
    }, {
      name: 'Recargas Canceladas',
      value: 'recharge_canceled'
    }, {
      name: 'Estorno de Recargas',
      value: 'recharge_refund'
    }, {
      name: 'Estorno de Recargas Canceladas',
      value: 'recharge_refund_canceled'
    }, {
      name: 'Consumos',
      value: 'consumption'
    }, {
      name: 'Consumos Cancelados',
      value: 'consumption_canceled'
    }, {
      name: 'Estorno de Consumo',
      value: 'consumption_refund'
    }, {
      name: 'Estorno de Consumo Cancelado',
      value: 'consumption_refund_canceled'
    }, {
      name: 'Ativações',
      value: 'card_activation'
    }, {
      name: 'Devoluções',
      value: 'card_return'
    }, {
      name: 'Sangrias',
      value: 'withdrawal'
    }];
  }

  public static get paymentStatuses(): Object[] {
    return [{
      name: 'Autorizado',
      value: 'authorized'
    }, {
      name: 'Cancelado',
      value: 'cancelled'
    }];
  }

  public static get customInfoTypes(): Object[] {
    return [{
      label: 'Número',
      name: 'number',
      value: '0'
    }, {
      label: 'Texto',
      name: 'text',
      value: '1'
    }, {
      label: 'CPF',
      name: 'cpf',
      value: '2'
    }, {
      label: 'CNPJ',
      name: 'cnpj',
      value: '3'
    }, {
      label: 'Telefone',
      name: 'telephone',
      value: '4'
    }, {
      label: 'Código de Barras/QRCode',
      name: 'barcode',
      value: '5'
    }, {
      label: 'Ingresso',
      name: 'ticket',
      value: '6'
    }];
  }

  public static get streetPrefixes(): any {
    return [{
      name: 'Rua',
      value: 'street'
    }, {
      name: 'Avenida',
      value: 'avenue'
    }, {
      name: 'Alameda',
      value: 'lane'
    }]
  }

  public static get amountTypes(): any {
    return [
      {
        id: 'fraction',
        name: 'Fracionado'
      }, {
        id: 'package',
        name: 'Inteiro'
      }
    ]
  }

  public static get brazilStates(): any {
    return [{
      name: 'Acre',
      value: 'AC'
    }, {
      name: 'Alagoas',
      value: 'AL'
    }, {
      name: 'Amapá',
      value: 'AP'
    }, {
      name: 'Amazonas',
      value: 'AM'
    }, {
      name: 'Bahia',
      value: 'BA'
    }, {
      name: 'Ceará',
      value: 'CE'
    }, {
      name: 'Distrito Federal',
      value: 'DF'
    }, {
      name: 'Espírito Santo',
      value: 'ES'
    }, {
      name: 'Goiás',
      value: 'GO'
    }, {
      name: 'Maranhão',
      value: 'MA'
    }, {
      name: 'Mato Grosso',
      value: 'MT'
    }, {
      name: 'Mato Grosso do Sul',
      value: 'MS'
    }, {
      name: 'Minas Gerais',
      value: 'MG'
    }, {
      name: 'Pará',
      value: 'PA'
    }, {
      name: 'Paraíba',
      value: 'PB'
    }, {
      name: 'Paraná',
      value: 'PR'
    }, {
      name: 'Pernambuco',
      value: 'PE'
    }, {
      name: 'Piauí',
      value: 'PI'
    }, {
      name: 'Rio de Janeiro',
      value: 'RJ'
    }, {
      name: 'Rio Grande do Norte',
      value: 'RN'
    }, {
      name: 'Rio Grande do Sul',
      value: 'RS'
    }, {
      name: 'Rondônia',
      value: 'RO'
    }, {
      name: 'Roraima',
      value: 'RR'
    }, {
      name: 'Santa Catarina',
      value: 'SC'
    }, {
      name: 'São Paulo',
      value: 'SP'
    }, {
      name: 'Sergipe',
      value: 'SE'
    }, {
      name: 'Tocantins',
      value: 'TO'
    }]
  }

  public static get printModes(): ConstantObject[] {
    return [{
      name: 'Ficha Padrão',
      value: 'normal'
    }, {
      name: 'Ficha Agrupada',
      value: 'grouped'
    }, {
      name: 'Ficha Agrupada com Senha',
      value: 'grouped_with_pass'
    }]
  }

  public static get unitMeasures(): any {
    return [{
      value: 'kilo',
      label: 'Quilo (kg)'
    }, {
      value: 'liter',
      label: 'Litro (l)'
    }, {
      value: 'gramme',
      label: 'Grama (g)'
    }, {
      value: 'milliliter',
      label: 'Mililitro (l)'
    }, {
      value: 'unit',
      label: 'Unidade (un)'
    }]
  }

  public static get productStatusFiscal(): any {
    return [{
      value: 'none',
      label: 'Não possui informações fiscais',
      color: 'dark-gray-color'
    }, {
      value: 'pending',
      label: 'Cadastro fiscal pendente',
      color: 'warning-color'
    }, {
      value: 'success',
      label: 'Informações fiscais válidas',
      color: 'success-color'
    }, {
      value: 'failure',
      label: 'Erro ao cadastrar informações fiscais',
      color: 'danger-color'
    }]
  }

  public static get invoiceModes(): any {
    return [{
      label: 'Assíncrono',
      value: 'async'
    }, {
      label: 'Síncrono',
      value: 'sync'
    }]
  }

  public static get invoiceTypes(): any {
    return [{
      label: 'Automático',
      value: 'automatic'
    }, {
      label: 'NFC-e',
      value: 'nfce'
    }]
  }

  public static get invoiceEnvironments(): any {
    return [{
      label: 'Homologação',
      value: 'staging'
    }, {
      label: 'Produção',
      value: 'production'
    }]
  }

  public static get invoiceStatus(): Object[] {
    return [{
      name: 'Autorizada',
      value: 'authorized',
      color: 'success-color'
    }, {
      name: 'Cancelada',
      value: 'cancelled',
      color: 'danger-color'
    }];
  }

  public static get eventIntegrationProviders(): Object[] {
    return [{
      label: 'Sympla',
      name: 'sympla',
      value: '0'
    }, {
      label: 'BeerMe',
      name: 'beerme',
      value: '1'
    }, {
      label: 'Outro',
      name: 'other',
      value: '2'
    }];
  }

  public static get validationStatuses(): Object[] {
    return [{
      name: 'Todos',
      value: '',
      color: ''
    }, {
      name: 'Sucesso',
      value: true,
      color: 'success-color'
    }, {
      name: 'Falha',
      value: false,
      color: 'danger-color'
    }]
  }

  public static get eventIntegrationStatus(): Object[] {
    return [{
      name: 'Pendente',
      value: 'pending',
      color: 'warning-color'
    }, {
      name: 'Processando',
      value: 'running',
      color: 'success-color'
    }, {
      name: 'Finalizada',
      value: 'completed',
      color: 'info-color'
    }]
  }

  public static get eventIntegrationAttendeeStatus(): Object[] {
    return [{
      name: 'Automático',
      value: 'automatic',
      color: 'success-color'
    }, {
      name: 'Sincronizando',
      value: 'synchronizing',
      color: 'warning-color'
    }]
  }

  public static get closureStatus(): ConstantObject[] {
    return [{
      name: 'Consolidado',
      value: 'consolidated'
    }, {
      name: 'Recalculando',
      value: 'consolidating'
    }, {
      name: 'Divergente',
      value: 'consolidated_with_problems'
    }, {
      name: 'Aprovado',
      value: 'approved'
    }, {
      name: 'Aguardando Análise',
      value: 'awaiting_analysis'
    }, {
      name: "Bloqueado",
      value: "blocked"
    }]
  }

  public static get creditAdjustOperationsStatus(): ConstantObject[] {
    return [{
      name: 'Aprovada',
      value: 'approved'
    }, {
      name: 'Reprovada',
      value: 'rejected'
    }, {
      name: 'Aguardando',
      value: 'waiting'
    }]
  }
}
