import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HttpParams } from '@angular/common/http';

@Component({
  template: ''
})
export class AuthComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const auth: any = {};
    const queryParams: any = this.route.snapshot.fragment;
    const params = new HttpParams({ fromString: queryParams });

    params.keys().forEach(key => {
      auth[key] = params.get(key);
    });

    this.authService.saveTokenData(auth);
    this.router.navigate(['main/operational/events']);
  }
}
