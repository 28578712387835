import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => {
        if (event instanceof NavigationEnd) {
          if ((<any>window).ga) {
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          }
          return true;
        }
        return false;
      }))
      .pipe(map(() => this.activatedRoute))
      .pipe(map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(switchMap(route => route.data))
      .subscribe((event: any) => {
        this.titleService.setTitle(event.title);
        const tip = document.querySelector('.ss-hover-tip');
        if (tip) {
          tip.parentNode?.removeChild(tip);
        }
      });

    if (!this.authService.getAccessToken()) {
      this.authService.signIn();
    }
  }
}
