import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//first party
import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { NotificationsComponent } from './header/notifications/notifications.component';
import { ShrinkMenuDirective } from './side-menu/shrink-menu.directive';
import { ToggleSubmenuDirective } from './side-menu/toggle-submenu.directive';
import { HasPermissionModule } from '../shared/directives/has-permission/has-permission.module';

@NgModule({
  declarations: [
    AuthComponent,
    HeaderComponent,
    SideMenuComponent,
    NotificationsComponent,
    ShrinkMenuDirective,
    ToggleSubmenuDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    HasPermissionModule
  ],
  exports: [
    HeaderComponent,
    SideMenuComponent
  ],
  providers: []
})
export class CoreModule {

}
